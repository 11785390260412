.my-playlist-main{
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    overflow-y: auto;
    font-Family: Imported !important;
}
.my-playlist-sidebar{
    margin-bottom: 5%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color:white;
    border-radius: 50rem;
    word-wrap: break-word;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    cursor: pointer;

}

.side-bar-main{
    background-color: #2a213a;
    display:block;
    height: 100%;
    width: 100%;
    font-family: Imported !important;
}


.side-bar-main-mobile{
    background-color: black;
    display:block;
    height: 100%;
    width: 80%;
}

.modal-language {
    z-index: 2000000000 !important; 
}

.active{
    background-color: #3b3b3b;
    color: white;
}
.side-bar-animation{
    width: 90%;
    height: 0.5px;
    background-color: red;
    animation-name: example;
    animation-duration: 1s;
    margin-top: 5px;
    margin-left: 13px;
    animation-iteration-count: infinite;
}

@keyframes example {
    from {background-color: red;}
     to {background-color: yellow;}
}

.text-rainbow-animation {
    width: 90%;
    height: 0.5px;
    /* background-color: red; */
    margin-top: 5px;
    /* margin-bottom: 10px; */
    margin-left: 13px;
    margin-bottom: 10px;
    font-family:arial black;
    font-size:70px;
    background-image: 
      linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet, red); 

      -webkit-text-fill-color: transparent;  
    animation: rainbow-animation 100s linear infinite;
  }
  @keyframes rainbow-animation {
    to {
        background-position: 4500vh;
    }
}

.vip-banner-slidebar {
    padding: 15px 8px;
    border-radius: 8px;
    margin: 0 20px;
    text-align: center;
    background-image: linear-gradient(117deg,#5a4be7,#c86dd7 102%);
}

.vip-banner-slidebar .vip-banner-des {
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 600;
    border-color: #ffdb00;
    line-height: 1.67;
    color: #fff;
}

.buy-vip-btn {
    font-size: 14px;
    border-radius: 999px;
    line-height: normal;
    border: 0;
    display: inline-block;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    position: relative
}

.vip-banner-slidebar .buy-vip-btn {
    background-color: #ffdb00;
    border-color: #ffdb00;
    color: #000;
    display: inline-block;
    padding: 6px 16px;
    font-size: 12px;
    font-weight: 600;
}