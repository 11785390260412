.playing-list-main{

    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #181818;

    height: 90vh;
}
.playing-list-top{
    width: 100%;
    height: 6vh;
    z-index: 1000;
    position: relative;
    display: flex;
    flex-direction: row;
    top:0;
    right: 0;
    background-color: #170F23
}




.drop-down-custom-wrap-playinglist{
    width: auto;
    height: 50%;
    margin: auto 10px auto auto
}
.drop-down-custom-btn-playinglist{
    width: 100%;
    height: 100% !important;
    background-color: #ebecf000 !important;
    margin: 0px;
    box-shadow: 0 0 0 0 rgb(235 236 240 / 0%) !important;
    padding: 0px !important;
}
.playing-list-top-img{
    width: 1.5vw;
    height: 1.5vw;
    margin: auto
}
.playing-list-title{
    padding: 1% 15%;
    width: auto;
    z-index: 1000;
    margin: auto 0px auto auto;
    text-align: center;
    color: white;
    font-size: 1vw;
    background-color: #B3B3B3;
    border-radius: 50rem;
}
.playing-list-song{
 
    padding: 0 3% 0 3%;
    background-color: #170F23;
    width: 100%;
    display: flex;
    height: 84vh;
    flex-direction: column;
}

.playing-list-song-detail{
    width: 100%;
    height: 35px;
    display: flex;
    flex-direction: row;
    padding: 0 2% 0 2%;
    border-radius: 1rem;
    margin-bottom: 4%;

}
.playing-list-song-detail:hover {
    background-color: #484848;
}
.playing-list-song-detail-avatar{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    height: 90%;
    width: 10%;
    border-radius: 1rem;  
}
.playing-list-song-detail-avatar:hover .playing-list-song-detail-avatar-img{
    width: auto;
    height: 50%;
    margin: 27% auto auto auto;
    display: block;
    
}
.playing-list-song-detail-avatar:hover {
    opacity: 0.4;
}
.onPlaying {
    opacity: 0.4;
}
.has-listened {
    opacity: 0.4;

}

.playing-list-song-detail-avatar-img{
    display: none;
}
.playing-list-song-detail-avatar-img-onplay{
    width: auto;
    height: 50%;
    margin: 27% auto auto auto;
    display: block;
}
.playing-list-song-detail-text{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 50%;
    margin-left: 5%;
    margin-top: auto;
    margin-bottom: auto;

}
.playing-list-song-detail-song-name{
    font-size: 1.5vh;
    color:white;
    word-wrap: break-word;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.playing-list-song-detail-artist{
    font-size: 1vh;
    color:white;
    display: flex;
    flex-direction: row;
    word-wrap: break-word;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.playing-list-song-detail-remove{
    position: absolute;
    right: 3%;
    /* margin-top: 5px; */
    
}
.playing-list-song-detail-play{
    position: absolute;
    right: 13%;
    /* margin-top: 5px; */
}


.playing-list-main .ps__rail-y{
    width: 0px;
}
.next-to{
    color: white;
    font-size: 1.2vw;
    margin-bottom: 2.5%;
}
.playing-list-song-like{
    margin:auto 0 auto auto;
    width: auto;
    height: 60%;
}
.drop-down-custom-wrap-more-song{
    width: auto;
    height: 60%;
    margin: auto 0 auto 10px;
}
.drop-down-custom-btn-more-song{
    width: 100%;
    height: 100% !important;
    background-color: #ebecf000 !important;
    margin: 0px;
    box-shadow: 0 0 0 0 rgb(235 236 240 / 0%) !important;
    padding: 0px !important;
}
.playing-list-song-more{
    margin:auto 0 auto 10px;
    width: auto;
    height: 100%;
}
