.main-child-dashboard{
    background-color: #181818;
    height: 83vh;
    width: 100%;
    display:block;
}
.main-child-dashboard-none{
    background-color: #181818;
    width: 100%;
    height: 93vh;
    display:block;
}
.main-child-dashboard-mobile{
    background-color: black;
    width: 100%;
    margin-top: 7vh;
    height: 93vh;
    display:block;
}

.main-child-dashboard-none .scrollbar-container{
    background-color: #170F23;
}


.main-child-dashboard .scrollbar-container{
    background-color: #170F23;
}

.MuiDivider-root{
    background-color: rgba(239, 239, 239, 19%) !important;
}

iframe { 
    display: none;
}

.show-drawer { 
    display: block;
    position: absolute ;
    top: 0;
    left : 0;
    z-index: 2000002;
    width : 100% !important;
}
