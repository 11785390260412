.rhap_volume-controls {
  width: 8%;
  position: fixed;
  top: 93.5%;
  right: 2%;
  display: block;
  flex: none;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
}
.rhap_volume-button {
  color: white;
  margin-bottom: 0px;
  font-size: 3vh;
  width: 28%;
  height: 100%;
  flex: 0 0 auto;
  margin-right: 11px;
}
.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 1vw;
  height: 1vw;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -6px;
  left: 0;
  top: -0.3vw;
  background: white;
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: rgb(134 134 134 / 50%) 0 0 3px;
  cursor: pointer;
}
.rhap_volume-bar {
  margin-top: auto;
  margin-bottom: auto;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 0.3vw;
  background: #dddddd;
  border-radius: 2px;
}
.rhap_volume-bar-area {
  align-items: unset;
}
.rhap_controls-section {
  flex-direction: row-reverse;
  flex: none;
  justify-content: unset;
}
.rhap_container {
  padding: unset;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 1%);
}
.rhap_main-controls {
  display: flex;
  flex-direction: row;
  flex: none;
}
.rhap_main-controls-button {
  margin: 0 3px;
  color: white;
  font-size: 3vh;
  width: auto;
  height: 100%;
}

.rhap_main-controls-button svg {
  width: 32px;
  height: 32px;
}
.rhap_stacked-reverse .rhap_controls-section {
  margin-bottom: 0px;
}

.rhap_repeat-button {
  font-size: 2.5vh;
  width: auto;
  height: auto;
  margin-left: 20px;
  color: white;
}

.rhap_additional-controls {
  flex: none;
  margin-right: auto;
}
.rhap_main .rhap_stacked-reverse {
  /* background-color: #170F23 */
  background-color: transparent;
}
.rhap_controls-section {
  /* background-color: #170F23; */
  background-color: transparent;
}
.rhap_progress-section {
  /* background-color: #170F23; */
  background-color: transparent;
}

.rhap_progress-bar {
  height: 0.2vw;
  background-color: #535353;
}
.rhap_time {
  color: white;
  font-size: 2vh;
}
.rhap_progress-container {
  height: 10px;
}
.rhap_progress-indicator {
  display: none;
}
.rhap_progress-filled {
  background-color: white;
}
.rhap_download-progress {
  background-color: #535353;
}

.footer .MuiMenu-paper {
  margin-top: -3% !important;
  outline: 0;

  background-color: #181818 !important;
  color: white !important;
}

legend {
  display: none;
}

fieldset {
  border: unset !important;
}

.btn-post-cmt {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: auto;
}

.btn-cancel-cmt,
.btn-submit-cmt {
  width: auto;
}
.btn-submit-cmt,
.btn-submit-cmt:hover {
  background-color: orange;
  margin-left: 10px;
}
.btn-cancel-cmt,
.btn-cancel-cmt:hover {
  background-color: rgba(0, 0, 0, 0);
}

.cmt-name-show {
  font-size: 14px;
  color: white;
}
.cmt-content-show {
  font-size: 14px;
  color: #b3b3b3;
}
.cmt-show {
  display: flex;
  flex-direction: column;
}
.cmt-show-btn {
  display: flex;
  flex-direction: row;
}
.btn-reply-cmt,
.btn-reply-cmt:hover {
  color: #b3b3b3;
  width: auto;
  height: 10%;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0);
}

.display-flex-row {
  display: flex;
  flex-direction: row;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}
.footer-shuffle-btn {
  position: relative;
  margin-bottom: auto;
  margin-top: auto;
  width: 2.2vh;
  margin-left: auto;
  margin-right: 20px;
}

.footer-repeat-btn {
  position: relative;
  margin-bottom: auto;
  margin-top: auto;
  width: 2.2vh;
  margin-left: 20px;
  margin-right: auto;
}

.footer-karaoke {
  width: 25%;
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
  padding-left: 10%;
}
.footer-karaoke-mobile {
  width: 25%;
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
.show-lyrics {
  background-color: rgb(235 236 240 / 0%) !important;
}
.hide-lyrics {
  opacity: 0;
  width: 25%;
  display: block;
  background-color: rgb(235 236 240 / 0%);
}
.hidee-lyrics {
  background-color: rgb(235 236 240 / 0%);
}
.change-lyrics {
  display: none !important;
}

.lyric-footer {
  position: fixed;
  top: 0px;

  width: 100%;
  height: 100%;
  background-image: url("../assets/background-lyric.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  display: flex;
  flex-direction: row;
  z-index: 2000000;
}

.footer-lyrics {
  color: white;
  font-size: 2vw;
  height: 60%;
  width: auto;
  word-break: break-word;
  white-space: pre-wrap;
}

.footer-lyrics-close-btn {
  position: fixed;
  top: 0px;
  right: 20px;
  color: white;
  font-size: 2vw;
  cursor: pointer;
}
.footer-lyrics-close-btn-mobile {
  position: fixed;
  top: 10px;
  left: 20px;
  color: white;
  font-size: 3vw;
  cursor: pointer;
}
.drop-down-custom-menu-footer {
  z-index: 9999999999999 !important;

  background-color: #282828 !important;
}
.drop-down-custom-menu-sub-footer {
  /* margin-left: 3%;
      margin-top: -175px; */
  width: fit-content;
}
.drop-down-custom-menu-sub-footer-playlist {
  width: fit-content;
}
.drop-down-custom-menu-sub-footer-playlist ul {
  height: 150px;
}

.drop-down-custom-btn {
  padding: 0 !important;
  height: auto !important;
  border-radius: 50rem !important;
  width: auto !important;
  background-color: #00000000 !important;
  color: #ffffff !important;
  display: unset !important;
  box-shadow: 0 0 0 3px rgb(235 236 240 / 0%) !important;
}
.drop-down-custom-items,
.drop-down-custom-menu-sub-footer,
.drop-down-custom-menu-sub-footer-playlist {
  background-color: #282828 !important;
  color: white;
}

.drop-down-custom-items:hover,
.drop-down-custom-btn:hover,
.drop-down-custom-menu-sub:hover,
.drop-down-custom-menu-sub-footer-playlist:hover {
  color: #ffa00e;
}
.drop-down-custom-btn:active {
  box-shadow: none !important;
}
.MuiPopover-root .MuiMenu-paper {
  background-color: #282828 !important;
  border-radius: 0px !important;
}

.rhap_container svg {
  vertical-align: bottom;
}

.drop-down-custom-wrap {
  margin-left: 3%;
}

.ring-phone-fullscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
}

.ringme-phone-fullscreen__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ringme-phone__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 56px;
  box-shadow: 0px 0px 20px 0px #151a351a;
}

.ringme-phone__header span {
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #151a35;
}

.ringme-phone__header--back {
  position: absolute;
  left: 15.6px;
}

.ring-phone-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 16px;
}

.ring-phone-item .ring-phone-item__left {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  flex: 1;
  gap: 7px;
}

.ring-phone-item .ring-phone-item__right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ring-phone-item__right button {
  background-color: #ff4d00;
  font-size: 12px;
  line-height: 20px;
  border-radius: 22px;
  color: white;
  outline: none;
  border: none;
  margin: 0;
  padding: 2px 10px;
}

.ring-phone-item__right button:disabled {
  background-color: #ff4d0078;
}

.ring-phone-item__audio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3px;
}

.ring-phone-item__audio .rhap_horizontal .rhap_controls-section {
  margin-left: 0;
}

.ring-phone-item__audio .rhap_horizontal .rhap_controls-section img {
  width: 22px;
  height: 22px;
}

.ring-phone-item__left--image {
  display: flex;
  height: 100%;
  margin-top: 3px;
}

.ring-phone-item__audio--duration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
}

.ring-phone-item__audio--duration span {
  color: #ff4c00;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}

.ring-phone-item__text {
  color: #151a35;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
}

.ring-phone-manager {
  position: relative;
  width: 100%;
  height: 100%;
}

.btn-cancel-crbt {
  height: 92px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* box-shadow: 0px 0px 20px 0px #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.btn-cancel-crbt button {
  background-color: #FF4C00;
  border-radius: 10px;
  font-size: 16px;
  color: white;
  font-weight: 500;
  line-height: 26px;
  border: none;
  outline: none;
  height: 48px;
  width: 358px;
}